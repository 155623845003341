@import url('https://cdnjs.cloudflare.com/ajax/libs/normalize/8.0.1/normalize.min.css')
@import 'bootstrap-icons/font/bootstrap-icons.css'
@import './custom.sass'
@import './authorBlock.sass'
@import './bookBlock.sass'


$gray-color: #6c757d

*
    box-sizing: border-box
    margin: 0 
    padding: 0 

#root
    padding-bottom: 30px

body
    overflow-y: scroll

.list-item__icon
    cursor: pointer
    margin-left: 10px
    &:first-child:hover
        color: #0277bd 
    &:nth-child(2):hover
        color: #e53935 
    &:nth-child(3):hover
        color: orange 