.book
    width: 90%
    margin: 30px auto 10px
    &__wrapper
        display: grid
        grid-template-columns: 35% 60%
        column-gap: 5%
        padding: 10px
        &_cover
            width: 400px
            height: 70vh
            object-fit: contain
    &__author
        font-size: 24px
        font-weight: bold
        display: inline
        &:hover
            color: #b71c1c
    &__name
        font-size: 32px
        font-weight: bold
    &__rating
        display: flex
        align-items: center
        justify-content: center
        width: 150px
        height: 150px
        background: url('../assets/bigStar.png') no-repeat center center
        background-size: cover
        font-size: 50px
        margin-top: 10px
        margin-bottom: 20px
    &__link
        text-decoration: none
        font-size: 18px
        color:#6c757d
    &__comment
        margin-top: 20px
        font-size: 22px
        text-align: justify
        font-style: italic
    &__icons
        margin: 15px auto
        width: 200px
        display: flex
        justify-content: space-between
        font-size: 18px

@media ( max-width: 991px )
    .book
        &__wrapper_cover
            width: 300px
        &__author
            font-size: 20px
        &__name
            font-size: 26px
        &__link
            font-size: 16px
        &__comment
            font-size: 18px

@media ( max-width: 830px ), ( min-width: 1400px )
    .book
        &__wrapper
            display: flex
            flex-direction: column
            align-items: center
            &_cover
                margin-top: 15px
                width: 280px
                height: 420px
                order: 1
            &_text
                text-align: center
                order: 0
        &__comment
            width: 95%
        &__rating
            margin: 10px auto 20px

@media ( max-width: 425px )
    .book
        &__wrapper_cover
            width: 95%
            height: 100%
            object-fit: cover
        &__author
            font-size: 18px
        &__name
            font-size: 24px
        &__link
            font-size: 14px
        &__rating
            width: 100px
            height: 100px
            font-size: 35px
            margin-bottom: 10px