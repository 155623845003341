.pageup
    width: 40px
    height: 40px
    position: fixed
    bottom: 96px
    right: 81px
    z-index: 100
    &:hover
        svg 
            path
                fill: #b71c1c
                opasity: unset